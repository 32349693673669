import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";

import BackgroundImage from "../images/BackgroundWaves.svg";
import Logo from "../images/Logo.svg";
import CityRoad from "../images/city-road-holding.svg";
import SEO from "../components/SEO";

const HoldingPage = () => (
    <Box
        sx={{
            "&::before": {
                content: '""',
                opacity: 0.3,
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                zIndex: -1,
                backgroundImage: `url(${BackgroundImage}?nocache=${Math.random()})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: {
                    xs: "165%",
                    md: "130%"
                },
                backgroundPosition: {
                    xs: "65% 25%",
                    md: "70% 15%"
                }
            },
            position: "absolute",
            zIndex: -1,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: "primary.main"
        }}
    >
        <Fade in={true} timeout={1000}>
            <Container
                sx={{
                    color: "common.white",
                    height: "100vh",
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gridTemplateRows: "repeat(3, 1fr)",
                    overflow: "auto"
                }}
            >
                <Box
                    sx={{
                        gridRowStart: 2,
                        gridColumnStart: 1,
                        gridRowEnd: 3,
                        gridColumnEnd: 2
                    }}
                >
                    <Box
                        component="img"
                        src={Logo}
                        sx={{
                            width: {
                                xs: "auto",
                                md: "465px"
                            },
                            mt: {
                                xs: 2,
                                md: 0
                            },
                            mb: 4
                        }}
                        alt="ModaMortgages logo"
                    />
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: {
                                xs: "2rem",
                                md: "3.125rem"
                            },
                            fontWeight: 500,
                            lineHeight: "120%",
                            mb: 1.5
                        }}
                    >
                        Get ready for ModaMortgages.
                        <br /> Brought to you by Chetwood Financial.
                    </Typography>
                    <Box
                        sx={{
                            maxWidth: {
                                md: "75%"
                            },
                            mb: 2.5
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "1.625rem",
                                fontWeight: 500,
                                lineHeight: "123.5%",
                                letterSpacing: "0.25px",
                                mb: 2.5
                            }}
                        >
                            The home of ‘smarter, faster, simpler’ buy to let mortgages, making it
                            easier for brokers and landlords.
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: "Hind",
                                fontSize: "1.125rem",
                                fontWeight: 300,
                                lineHeight: "150%",
                                letterSpacing: "0.15px"
                            }}
                        >
                            We’re a buy to let specialist mortgages lender soon to be offering
                            competitive buy to let products for all types of landlords - including
                            small or large portfolios, limited companies, individuals, first-time
                            buyers and first-time landlords, through a nationwide network of
                            mortgage brokers.
                        </Typography>
                    </Box>
                    <Box display="flex" flexWrap="wrap">
                        <Box
                            src={CityRoad}
                            alt="City Road Communications logo"
                            sx={{
                                height: "66px",
                                width: "161px",
                                backgroundImage: `url(${CityRoad})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "100% 100%",
                                mb: 2,
                                mr: {
                                    sm: 2.75
                                }
                            }}
                        />
                        <Typography
                            sx={{
                                fontFamily: "Hind",
                                fontSize: "1.25rem",
                                fontWeight: 300,
                                lineHeight: "150%",
                                letterSpacing: "0.15px"
                            }}
                        >
                            Please direct all media and press enquiries to City Road Communications.
                            <br />
                            Email:{" "}
                            <Link
                                color="common.white"
                                href="mailto:dominic@cityroadcomms.co.uk"
                                sx={{ fontWeight: "500" }}
                            >
                                dominic@cityroadcomms.co.uk
                            </Link>
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        gridRowStart: 3,
                        gridColumnStart: 1,
                        gridRowEnd: 4,
                        gridColumnEnd: 2,
                        alignSelf: "end",
                        mb: 5
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Hind",
                            fontSize: "1rem",
                            fontWeight: 500
                        }}
                    >
                        Copyright© 2023. ModaMortgages. All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </Fade>
    </Box>
);

export const Head = () => (
    <SEO
        title="ModaMortgages: Specialist buy to let for intermediaries"
        description="Smarter, faster, simpler mortgages  • Clear criteria up front  • 100% online application • Quick processing • A dedicated, straight-talking support team"
    />
);

export default HoldingPage;
